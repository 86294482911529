
.icon-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.icon-wrap{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid var(--v-primary-base);
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-wrap{
        width: 160px;
        height: 160px;
    }
}
@media (min-width:1200px){
}

